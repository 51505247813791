@import '~react-redux-toastr/src/styles/index';

.home-container {
  background-size: cover;
  margin-top: 15em;
}

.btn-link, a {
  color: #00c4cc;
  padding: 0px;

  &:hover {
    color: #00c4cc;
  }
}

.bg-info, .btn-info {
  background-color: #00c4cc !important;
}

.text-info {
  color: #00c4cc !important;
  margin-bottom: 30px;
}

.lead {
  margin-bottom: 50px;
  font-size: 22px;
}

.side-note {
  text-align: center;
  color: gray;
  font-size: 14px;
  margin-top: 3px;
}

body {
  margin-top: 3em;
}

.space-listing-item-container {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  transform: translate3d(0,0,0);
  transition: transform 0.3s;

  &:hover {
    transform: translate3d(0,-6px,0);
  }
}

.space-listing-item {
  background: #00c4cc;
  border-radius: 8px;
  height: 100%;
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 200px;
  z-index: 100;
  cursor: pointer;
  margin-bottom: 0.5em;

  &:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(to top, rgba(35,35,37,0.9) 0%, rgba(35,35,37,0.55) 35%, rgba(22,22,23,0.1) 60%, rgba(0,0,0,0) 100%);
    background-color: rgba(35,35,37,0.2);
    border-radius: 8px;
    opacity: 1;
  }

  .space-listing-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  .space-listing-content {
    position: absolute;
    bottom: 23px;
    left: 0;
    padding: 0 32px;
    padding-left: 28px;
    padding-right: 90px;
    width: 100%;
    z-index: 50;
  }
}

.space-listing-space-image {
  background: #00c4cc;
  border-radius: 8px;
  height: 100%;
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 265px;
  z-index: 100;
  cursor: pointer;

  .space-listing-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
}

.individual-space-carousel {
  margin-left: -15px;
  margin-right: -15px;

  .col-md-12 {
    padding: 0px;
  }

  .carousel, .individual-space-item {
    background-color: rgba(0,0,0,0.9);
  }

  img {
    height: 30em;
    object-fit: contain;
  }
}

ul {
  padding-inline-start: 20px;
}

h1, h2, h3, h4, h5, h6, p, li {
  color: #343a40;
}

html,
body {
  margin:0;
  padding:0;
  height:100%;
}

#root {
  min-height:100%;
  position:relative;
}

.App {
  padding:10px;
  padding-bottom: 8rem;   /* Height of the footer */
}

footer {
  padding: 1rem;
  text-align: center;
  position: absolute;
  background-color: #f5f5f5;
  bottom: 0;
  width: 100%;
  height: 6rem;   /* Height of the footer */
}

$stepColor: #bdbdbd;
$stepColorHover: #138496;
$stepColorCompleted: #00c4cc;
$textColor: #343a40;
$textColorCompleted: #fff;

.step-indicator {
  line-height: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .step-indicator {
    display: none;
  }

  div.App {
    padding-bottom: 10em;
  }

  footer {
    height: 8em;
  }
}

.step {
  cursor: pointer;
  display: block;
  white-space: nowrap;
  width: 100%;
  float: left;
  background: $stepColor;
  padding-right: 10px;
  height: 40px;
  line-height: 40px;
  margin-right: 43px;
  position: relative;
  text-decoration: none;
  color: $textColor;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: -40px;
    border: 20px solid transparent;
    border-color: $stepColor;
    border-left-color: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: -40px;
    border: 20px solid transparent;
    border-left-color: $stepColor;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:first-of-type {
    border-radius: 2px 0 0 2px;
    padding-left: 15px;

    &:before {
      display: none;
    }
  }

  &:last-of-type {
    border-radius: 0 2px 2px 0;
    margin-right: 0;
    padding-right: 15px;

    &:after {
      display: none;
    }
  }

  &.completed {
    background: $stepColorCompleted;
    color: $textColorCompleted;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:before {
      border-color: $stepColorCompleted;
      border-left-color: transparent;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:after {
      border-left-color: $stepColorCompleted;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:hover {
      background: $stepColorHover;
      border-color: $stepColorHover;
      color: $textColorCompleted;
      text-decoration: none;

      &:before {
        border-color: $stepColorHover;
        border-left-color: transparent;
      }

      &:after {
        border-left-color: $stepColorHover;
      }
    }
  }
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .thumb {
  cursor: pointer;
}

.carousel .slide img {
  object-fit: contain;
  max-height: 30em;
}

.carousel .slide {
  height: 30em;  /* change this to whatever you want */
  width: auto;
  display: flex;
  justify-content: center;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  display: flex;
}

.individual-space-main-carousel .slide {
  cursor: pointer;
}

.carousel .control-arrow {
  opacity: 1 !important;
  background-color: rgba(0,0,0,.4) !important;
}

.carousel .control-arrow:hover {
  background-color: rgba(0,0,0,.7) !important;
}
